import {stringToBoolean} from './utils'

let indexName = "hasBeenRegistered";
export const libraryIndex = function () {
    let hasBeenRegistered = localStorage.getItem (indexName);
    if (!hasBeenRegistered) {
        return false
    } else {
        console.log("hasBeenRegistered", true)
        return stringToBoolean(hasBeenRegistered)
    }
}

export const saveHasBeenRegistered = function (val) {
    localStorage.setItem (indexName, val)
}

export const registerEventMaker = function (data) {
    return new CustomEvent('registerEvent', {
        detail: data
    })
}
export const registeredEventMaker = function (data) {
    return new CustomEvent('registeredEvent', {
        detail: data
    })
}

export const download = function (id) {
    let a = document.getElementById (`libItem-${id}`)
    a.click()
}