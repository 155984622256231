<template>
    <section class="toolbar_search-box toolbar_search-box--closed d-md-block w-100">
        <form autocomplete="off" class="w-100 d-flex align-items-center" @submit.prevent="doSearch">
            <div class="close-toolbar-search toolbar_search_icon icon_cross icon_color-black mr-2 mr-lg-0 d-block d-md-none"></div>
            <div class="form-search flex-grow-1">
                <input v-model="searchVal" id="search-lib" type="text" placeholder="BUSCAR" class="w-100 h-100 text_montserrat-light text-size_15">
            </div>
        </form>
    </section>
</template>

<script>

export default {
    data () {
        return {
            searchVal: "",
            urlParams: new URLSearchParams(window.location.search),
        }
    },
    created () {
        this.searchVal = this.urlParams.get("searchParam") || ""
    },
    methods: {
        doSearch () {
            let currVal = this.urlParams.get("searchParam")
            this.urlParams.set("searchParam", this.searchVal)
            if (window.location.search[0] == '?') {
                window.location.href = window.location.origin+"/library"+"?"+this.urlParams.toString()
            } else {
                window.location.href = window.location.origin+"/library"+"?searchParam="+this.urlParams.get("searchParam")
            }
        }
    }
}
</script>