<template>
    <el-form class="" ref="form" :model="form" :rules="formRules" @submit.native.prevent="onSubmit ()" v-loading="loading">
        <div class="overflow-hidden">
            <div class="position-relative">
                <div class="row background-color_tertiary demo-form_header position-relative" v-if="extended">
                    <Swiper :options="swiperOptions" ref="carouselClients" class="contact-carousel">
                        <SwiperSlide class="col-12 col-lg-6 text-color_white text-center " v-for="(settlement, index) in settlements" :key="index">
                            <div class="row h-100 px-3 px-lg-3" style="place-content: center;">
                                <div class="col-12 text_montserrat-bold text-size_20">
                                    <div class="row justify-content-center">
                                        <div class="ml-1 k-icon-fix-pos">
                                            <k-icon icon="marker"></k-icon>
                                        </div>
                                        <span class="ml-2">{{settlement.title}}</span>
                                    </div>
                                </div>

                                <div class="col-12 text_montserrat-regular text-size_15 mt-2">{{settlement.description}}</div>
                            </div>
                        </SwiperSlide>

                    </Swiper>
                    <div ref="settlementsSwiperPagination" id="settlementsSwiperPagination" class="swiper-pagination d-md-none"></div>
                </div>
 
                <div class="row align-items-start justify-content-center mt-3 mt-lg-5 mb-2 mb-lg-2 position-relative" style="z-index: 10;">
                    <div class="demo-form_bob" :class="{'demo-form_bob--flat': isFlat}">
                        <img class="img-fluid" :src="bobImage" alt="Bob">
                    </div>

                    <div class="text-color_secondary d-flex demo-form_close" :class="{'close-contact-modal': isModal}" v-if="!hideClose">
                        <div class="background-color_main rounded-icon rounded-icon--medium k-icon-fix-pos text-color_white cursor-pointer">
                            <k-icon icon="cross"></k-icon>
                        </div>
                    </div>

                    <div class="main-gradient flex-grow-1 header-badge demo-form_left-bar d-none d-md-flex"></div>
                    <div class="mx-3 demo-form_title text-md-left text-center mt-n1">
                        <div class="text_montserrat-bold text-size_17 text-color_main">SOLICITA UNA DEMO O UNA ASESORÍA</div>
                        <div class="text_montserrat-medium text-size_15 text-color_secondary">Completa el siguiente formulario y un ejecutivo se pondrá en contacto</div>

                        <el-form-item class="text_montserrat-medium text-color_main mt-2" prop="solutionsOrInfrastructure">
                            <div class="ml-1">INFRAESTRUCTURA</div>
                            <el-select class="ml-0 demo-form_select" v-model="form.infrastructure" multiple collapse-tags style="margin-left: 20px;" placeholder="INFRAESTRUCTURA"><el-option
                                v-for="item in infrastructureOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
                            </el-select>
                            
                            <div class="ml-1">SOLUCIONES</div>
                            <el-select class="ml-0 demo-form_select text_montserrat-medium" v-model="form.solutions" multiple collapse-tags style="margin-left: 20px;" placeholder="SOLUCIONES"><el-option
                                v-for="item in solutionsOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
                            </el-select>
                        </el-form-item>

                        <!-- <el-form-item class="text_montserrat-medium text-color_main" prop="solutions"> 
                            <div class="ml-1">SOLUCIONES</div>
                            <el-select class="ml-0 demo-form_select text_montserrat-medium" v-model="form.solutions" multiple collapse-tags style="margin-left: 20px;" placeholder="SOLUCIONES"><el-option
                                v-for="item in solutionsOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
                            </el-select>
                        </el-form-item> -->
                    </div> 
                    <div class="main-gradient_backwards flex-grow-1 header-badge d-none d-md-flex"></div>
                </div>

                <div class="row justify-content-center px-1">
                    <div class="col-12 col-lg-10 demo-form_body">
                        <div class="row">
                            <el-form-item class="col-12 col-lg-12" prop="name">
                                <el-input v-model="form.name" class="form-field" placeholder="*NOMBRE COMPLETO"></el-input>
                            </el-form-item>
                            <el-form-item class="col-12 col-lg-12" prop="company">
                                <el-input v-model="form.company" class="form-field" placeholder="*EMPRESA"></el-input>
                            </el-form-item>
                            <el-form-item class="col-12 col-lg-12" prop="email">
                                <el-input v-model="form.email" class="form-field" placeholder="*EMAIL"></el-input>
                            </el-form-item>
                            <el-form-item class="col-12 col-lg-12" prop="phone">
                                <el-input v-model="form.phone" class="form-field" placeholder="*TELÉFONO"></el-input>
                            </el-form-item>
                        </div>

                        <div class="row text-center py-2">
                            <div class="col-12">
                                <span class="text_montserrat-medium text-size_17 text-color_gray">Selecciona una fecha y un horario para recibir nuestra llamada</span>
                            </div>

                            <div class="col-12">
                                <div class="d-flex justify-content-center mt-2 mt-lg-2">
                                    <el-date-picker class="demo-form_date" v-model="form.date" type="datetime" format="dd/MM/yyyy HH:mm"></el-date-picker>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row mx-0" :class="{'demo-form_bottom': !hideBottom}" style="padding-top: 4rem">
                    <div class="primary-button cursor-pointer background-color_main text-size_15 text_montserrat-semibold mx-auto align-self-center px-4"
                    @click="onSubmit">
                        <div class="text-color_white "><span class="">Enviar</span></div>
                    </div>

                    <div class="col-12 text-center">
                        <span class="text-size_12 text_montserrat-medium text-color_gray">*Todos los campos son obligatorios</span>
                    </div>
                    <div class="col-12 text-center">
                        <a href="/aviso-privacidad.pdf" target="_blank" class="text-size_9 text_montserrat-medium text-color_gray py-2">Aviso de privacidad</a>
                    </div>
                </div>

                
            </div>
        </div>
    </el-form>
</template>

<script>
import KIcon from './icons/KIcon'
import {rules} from '../static/rules'
import {options} from '../static/vueSwiperCarousel.js'
import {settlements, infrastructureOptions, solutionsOptions} from '../static/contacForm'
import axios from 'axios'

import getAwesomeSwiper from 'vue-awesome-swiper/dist/exporter'
import {Swiper as SwiperClass, Pagination, Autoplay} from 'swiper/core'

SwiperClass.use([Pagination, Autoplay])
const {Swiper, SwiperSlide} = getAwesomeSwiper(SwiperClass)

export default {
    components: {
        KIcon, Swiper, SwiperSlide
    },
    props: {
        urlAction: {
            type: String,
            default: '/send-contact-mail'
        },
        token: {
            type: String,
            required: true
        },
        extended: {
            type: Boolean,
            default: false
        },
        isModal: {
            type: Boolean,
            default: false
        },
        hideBottom: {
            type: Boolean,
            default: false
        },
        hideClose: {
            type: Boolean,
            default: false
        },
        isFlat: {
            type: Boolean,
            default: false
        },
        bobImage: {
            type: String,
        },
    },
    data () {
        return {
            form: {
                date: new Date(),
                solutionsOrInfrastructure: undefined,
            },
            today: new Date(),
            loading: false,
            formRules: rules.demoForm,
            swiperOptions: options ("#settlementsSwiperPagination"),
            settlements: settlements,
            infrastructureOptions: infrastructureOptions,
            solutionsOptions: solutionsOptions
        }
    },
    methods: {
        onSubmit () {
            this.form.solutionsOrInfrastructure = this.form.infrastructure.length > 0 || this.form.solutions.length > 0

            this.$refs['form'].validate(async (valid) => {
                if (valid) {
                    await this.sendForm ()
                    this.loading = false
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        async sendForm () {
            this.loading = true
            let body = {
                star_value: "5",
                lead_score: "100",
                tags: ["webcontact"],
                properties: this.postProperties,
                token: this.token,
            }
            await axios.post (this.urlAction, body).then (res => {
                console.log("Saved to crm")
                this.$refs['form'].resetFields()
                this.$notify({ title: 'Tu cita fue agenda con éxito, pronto nos pondremos en contacto contigo', duration: 3000, type: 'success'});
            }).catch (error => {
                this.$notify({ title: 'El servicio de correos no esta disponible', duration: 3000, type: 'error'});
                console.error ("Error on Demo Form", error)
            })
        },
    },
    mounted () {
        
    },
    computed: {
        formDate () {
            return this.form.date
        },
        cSwiperOptions () {
            return this.swiperOptions
        },
        // properties for api {type, name, value, subtype?}
        postProperties () {
            return [
                {
                    type: "SYSTEM",
                    name: "first_name",
                    value: this.form.name
                },
                {
                    name: "company",
                    type: "CUSTOM",
                    value: this.form.company
                },
                {
                    type: "SYSTEM",
                    name: "email",
                    value: this.form.email
                },
                {
                    name: "phone",
                    subtype: "home",
                    value: this.form.phone
                },
                {
                    name: "selected date",
                    type: "CUSTOM",
                    value: this.form.date.toLocaleDateString()
                },
                {
                    name: "infrastructures",
                    type: "CUSTOM",
                    value: this.form.infrastructure.join(", ")
                },
                {
                    name: "solutions",
                    type: "CUSTOM",
                    value: this.form.solutions.join(", ")
                },
            ]
        }
    },
    watch: {
        formDate: {
            handler (newVal, oldVal) {
                if (newVal - this.today < 0) {
                    this.$notify({ title: 'Fecha invalida', message: "Favor de seleccionar una fecha posterior a la actual", duration: 3000, type: 'warning'});
                    this.form.date = oldVal
                }
            },
        }
    }
}
</script>