import anime from 'animejs'
import $ from 'jquery'

$(document).ready(() => {
    window.socialAnimationActive = window.socialAnimationCooldown = window.bobAnimationActive = window.bobAnimationCooldown = false
    let hasScrolled = false
    let triedToHide = false
    let scroll = 0;
    let tl = initAnim ()
    window.tl = tl

    window.addEventListener ('wheel', function (event) {
        let scrollUp = event.deltaY > 0
        let scrollDown = event.deltaY < 0
        if (scrollUp && !hasScrolled) {
            triedToHide = false
            hasScrolled = true
            showHomeContentAnim ()
        }
        
        if (scroll == 0 && scrollDown && hasScrolled) {
            if (triedToHide) {
                hasScrolled = false
                hideHomeContentAnim ()
            } else {
                triedToHide = true
            }
        }
    })

    $(window).on ("scroll", function () {
        scroll = this.scrollY
        socialMediaAnim (scroll)
        bobAnim (scroll)
        bobAlmostAtBottom (scroll)
    })

    toggleDemoForm ()
    
    function toggleDemoForm () {
        let el = "#demo-form-meant-to-be-hidden";
        $(".demo-form_close").click(() => {
            if (!$(el).hasClass("from-right")) {
                $(el).addClass("from-right")
            }
        })
        
        $(".demo-form_open").click(() => {
            if ($(el).hasClass("from-right")) {
                $(el).removeClass("from-right")
            }
        })
    }

    function socialMediaAnim (scroll) {
        let threshold = $(window).height() / 2
        let animClass = "from-right--social-media"
        let classToAnim = ".social-media"

        // console.log(scroll, threshold, window.socialAnimationActive, window.socialAnimationCooldown)
        if (scroll >= threshold && !window.socialAnimationActive && !window.socialAnimationCooldown) {
            $(classToAnim).removeClass (animClass)
            window.socialAnimationActive = true
            window.socialAnimationCooldown = true

            setTimeout(() => {
                window.socialAnimationCooldown = false
            }, 1000);
            
        } else if (scroll < threshold && window.socialAnimationActive && !window.socialAnimationCooldown) {
            $(classToAnim).addClass (animClass)
            setTimeout(() => {
                window.socialAnimationActive = false
            }, 500);
        }
    }

    function bobAnim (scroll) {
        let threshold = $(window).height() / 2
        let animClass = "from-right"
        let classToAnim = ".home_bob"

        if (scroll >= threshold && !window.bobAnimationActive && !window.bobAnimationCooldown) {
            $(classToAnim).removeClass (animClass)
            window.bobAnimationActive = true
            window.bobAnimationCooldown = true

            setTimeout(() => {
                window.bobAnimationCooldown = false
            }, 1000);
            
        } else if (scroll < threshold && window.bobAnimationActive && !window.bobAnimationCooldown) {
            $(classToAnim).addClass (animClass)
            setTimeout(() => {
                window.bobAnimationActive = false
            }, 500);
        }
    }

    function bobAlmostAtBottom (scroll) {
        let scrollOffset = $("body").height()-$(window).height() - 100;
        if (scroll >= scrollOffset) {
            $(".home_bob").css({bottom: '100px'})
        } else {
            $(".home_bob").css({bottom: '0px'})
        }
    }

    function showHomeContentAnim () {
        if (!tl) return
        if (!window.isMobile) {
            setTimeout(() => {
                $("body").css({overflow: 'unset'})
            }, 500);
        }
        
        if (tl.reversed) {
            tl.reverse()
        }
        tl.duration = 4000
        tl.play()
    }
    function hideHomeContentAnim () {
        if (!tl) return
        $("body").css({overflow: 'hidden'})
        if (!tl.reversed) {
            tl.reverse()
        }
        tl.duration = 1000
        tl.restart ()
    }

    function initAnim () {
        if (window.innerWidth <= 768) {
            return null
        }
        let tl = anime.timeline({
            easing: 'easeOutExpo',
            duration: 2000,
            begin: function () {
                $('.anime-fade-up').css ({opacity: 0})
            },
            complete: function () {
                setTimeout(() => {
                    $('#middle-video').get(0).play()
                }, 1000);
            }
        });
        tl.add({
            targets: "#home_verticales",
            maxWidth: [0, document.body.clientWidth]
        })
        tl.add ({
            targets: ".anime-fade-up",
            opacity: [0, 1],
            transformY: [-50, 0],
            delay: anime.stagger(1500),
        }, "-=1500")
        tl.pause()
        return tl
    }
})
