export const options = (ref) => {
  const opts ={
    observer: true,
    observeParents: true,
    slidesPerView: 2,
    fadeEffect: true,
    pagination: {
      el: ref,
      type: 'bullets',
      clickable: true,
    },
    autoplay: 2000,
    autoplayDisableOnInteraction: false,
    debugger: true,
    Observer: true, /// automatically initializes swiper when modifying swiper itself or child elements
    ObserveParents: true,
    breakpoints: {
        0: {
          slidesPerView: 1,
          loop: true,
        },
        1000: {
          slidesPerView: 2,
        }
    },
  }
  return opts
}