import Swiper from 'swiper/bundle';
import 'swiper/swiper-bundle.css'

import {makeSlideTransitions} from '../animations/home/middleTextCarousel'

window.Swiper = Swiper

$(document).ready(() => {
  let swiperBlogsHome = new Swiper('.home_blogs', {
      mode:'horizontal',
      observer: true,  
      observeParents: true,
      loop: true,
      pagination: {
          el: '.home_blogs_pagination',
          clickable: true,
          renderBullet: function (index, className) {
            return `<span class="dot swiper-pagination-bullet home_blogs_bullet"></span>`;
          },
      },
      autoplay: {
          disableOnInteraction: false,
          delay: 3000,
      },
      spaceBetween: 10,
      breakpoints: {
          320: {
            slidesPerView: 1,
          },
          720: {
            slidesPerView: 2,
          },
          1000: {
            slidesPerView: 3,
          }
      },
  });

  let swiperBadgesHome = new Swiper('.home_badge_carousel', {
      mode:'horizontal',
      observer: true,  
      observeParents: true,
      loop: true,
      effect: 'fade',
      fadeEffect: { crossFade: true },
      autoplay: {
          disableOnInteraction: false,
          delay: 5000,
      },
      slidesPerView: 1,
      pagination: {
        el: '.home_badge_pagination',
        clickable: true,
        renderBullet: function (index, className) {
          return `<span class="dot swiper-pagination-bullet home_badge_bullet"></span>`;
        },
      },
  });

  new Swiper('.blogs-page_carousel', {
      observer: true, 
      observeParents: true,
      loop: true,
      pagination: {
          el: '.blogs-page_pagination',
          clickable: true,
          renderBullet: function (index, className) {
            return `<span class="dot swiper-pagination-bullet blogs-page_bullet"></span>`;
          },
      },
      autoplay: {
          disableOnInteraction: false,
          delay: 3000,
      },
      slidesPerView: 1,
  });
})

window.homeCarousel = function (items) {
  let changeHomeCarouselData = function (index) {
    $("#homeCarouselTitle").html(items[index].title);
    $("#homeCarouselAction").attr("href", items[index].action);
    $("#homeCarouselImage").attr("src", items[index].image);
    $("#homeCarouselImage").attr("class", items[index].class);
  }

  var homeCarousel = new Swiper('.home_carousel', {
    observer: true, 
    observeParents: true,
    loop: true,
    pagination: {
        el: '.home_carousel_pagination',
        clickable: true,
        renderBullet: function (index, className) {
            return `<span class="dot swiper-pagination-bullet home_carousel_bullet"></span>`;
        },
    },
    effect: 'fade',
    fadeEffect: { crossFade: true },
    autoplay: {
        disableOnInteraction: false,
        delay: 5000,
    },
  });

  homeCarousel.on('slideChange', function () {
      changeHomeCarouselData (homeCarousel.realIndex)
  });

  changeHomeCarouselData (0)

  const transitions = makeSlideTransitions (homeCarousel);
  homeCarousel.on ('slideChangeTransitionStart', transitions.slideTransitionStart)
  homeCarousel.on ('slideChangeTransitionEnd', transitions.slideTransitionEnd)
}

window.aboutUsFunctionality = function () {
  $(document).ready(function () {
    window.aboutUsCarousel = new Swiper('.about-us_carousel', {
        loop: true,
        observer: true, 
        spaceBetween: 10,
        observeParents: true,
        autoplay: {
            disableOnInteraction: false,
            delay: 3000,
        },
        pagination: {
            el: '.about-us_carousel-pagination',
            clickable: true,
            renderBullet: function (index, className) {
                return `<span class="dot swiper-pagination-bullet about-us_carousel-bullet"></span>`;
            },
        },
        breakpoints: {
            320: {
                slidesPerView: 1,
                slidesPerGroup: 1
            },
            720: {
                slidesPerView: 3,
                slidesPerGroup: 2
            },
            1000: {
                slidesPerView: 6,
                slidesPerGroup: 3
            }
        },
    });

    window.init = false;
    function swiperMode() {

        let mobile = window.matchMedia('(min-width: 0px) and (max-width: 1024px)');
        let desktop = window.matchMedia('(min-width: 1025px)');

        if(mobile.matches) {
            if (!window.init) {
                $('#certsSwiperWrapper').removeClass ("row")

                $('#certsSwiperContainer').addClass("swiper-container")
                $('#certsSwiperWrapper').addClass("swiper-wrapper")
                $('#certsSwiperWrapper > div').each (function (i, el) {
                    $(el).removeClass ("col-12")
                    $(el).removeClass ("col-lg-auto")
                    $(el).addClass ("swiper-slide")
                })

                window.aboutUsCertsCarousel = new Swiper('.certifications_carousel', {
                    loop: false,
                    observer: true, 
                    spaceBetween: 10,
                    observeParents: true,
                    initialSlide: 0,
                    touchEventsTarget: 'wrapper',
                    activeLoopIndex: true,
                    pagination: {
                        el: '.certifications_carousel-pagination',
                        clickable: true,
                        type: 'bullets',
                        renderBullet: function (index, className) {
                            return `<span class="dot swiper-pagination-bullet certifications_carousel_bullet"></span>`;
                        },
                    },
                    autoplay: {
                        disableOnInteraction: true,
                        delay: 3000,
                    },
                    cache: false,
                    direction: "horizontal",
                    breakpoints: {
                        320: {
                            slidesPerView: 1,
                        },
                        720: {
                            slidesPerView: 3,
                        },
                        1000: {
                            slidesPerView: 5,
                        }
                    }
                });
                window.init = true
            }
        }

        // Disable (for desktop)
        else if(desktop.matches) {
            window.aboutUsCertsCarousel.destroy();
            $('#certsSwiperWrapper').addClass ("row")

            $('#certsSwiperContainer').removeClass("swiper-container")
            $('#certsSwiperWrapper').removeClass("swiper-wrapper")
            $('#certsSwiperWrapper > div').each (function (i, el) {
                $(el).addClass ("col-12")
                $(el).addClass ("col-lg-auto")
                $(el).removeClass ("swiper-slide")
            })

            window.init = false;
        }
    }

    window.addEventListener('load', function() {
        swiperMode();
    });

    /* On Resize
    **************************************************************/
    window.addEventListener('resize', function() {
        swiperMode();
    });
  })
}