import "@/app/styles/app.scss"
import "./plugins/swiper";
import "./plugins/vue";
import "./plugins/events";
import "./plugins/library";
import "./plugins/animations";

import "aos/dist/aos.css"
import Preloader from "./plugins/preloader";

Preloader.setPreloader();