import $ from 'jquery'
window.$ = $

$(document).ready(() => {
    $("#contact-modal").fadeOut(1)

    $('.scrollTo').click(function(){
        $('html, body').animate({
            scrollTop: $( $(this).attr('href') ).offset().top
        }, 500);
        return false;
    });

    $(".toggle-menu").on('click', () => {
        if ($("#menu-opener").hasClass('header_bars--cross')) {
            closeMenu ();
        } else {
            openMenu ();
        }
    })

    $("#demo-button").on("click", () => {
        let threshold = $(window).height()
        if (location.pathname == "/") // Solo at home
            scrollIfNotAtOffset (threshold)
    })

    $(".close-contact-modal").on('click', () => {
        $("#contact-modal").fadeOut(500)
    })
    $(".open-contact-modal").on('click', () => {
        $("#contact-modal").fadeIn(500)
    })

    // $(".swiper-container").each(function(elem, target){
    //     var swp = target.swiper;
    //     $(this).hover(function() {
    //         swp.autoplay.stop();
    //     }, function() {
    //         swp.autoplay.start();
    //     });
    // });

    $(".search-lib").on('keyup', function () {
        if (e.key === 'Enter' || e.keyCode === 13) {
        }
    })

    modalCloseEvents ();
})

function openMenu () {
    let threshold = $(window).height()
    if (location.pathname == "/") // Solo at home
        scrollIfNotAtOffset (threshold)

    $("#header-menu").fadeIn(250);
    $("#menu-opener").addClass("header_bars--cross");
}

function closeMenu () {
    $("#header-menu").fadeOut(250);
    $("#menu-opener").removeClass("header_bars--cross");
}

function scrollIfNotAtOffset (threshold) {
    let scroll = $(window).scrollTop();
    if (scroll < threshold) {
        $('html, body').animate({scrollTop: threshold}, 500);
    }
}

function modalCloseEvents () {
    let closerItems = $(".modal-closer")
    let openerItems = $(".modal-opener")
    
    closerItems.each((_, item) => {
        $(item).on("click", function () {
            $("html").removeClass ("overflow-hidden")
            // if ($(`#${target}`).length <= 0) {
            //     console.warn ("Modal " + target + " does not exists")
            //     return
            // }
            let target = $(this).data("target")
            $(`#${target}`).fadeOut(500);
        })
    });
    
    openerItems.each((_, item) => {
        $(item).on("click", function () {
            // if ($(`#${target}`).length <= 0) {
            //     console.warn ("Modal " + target + " does not exists")
            //     return
            // }
            $("html").addClass ("overflow-hidden")
            let target = $(this).data("target")
            $(`#${target}`).fadeIn(500);
        })
    });
}
