var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      staticClass:
        "toolbar_search-box toolbar_search-box--closed d-md-block w-100"
    },
    [
      _c(
        "form",
        {
          staticClass: "w-100 d-flex align-items-center",
          attrs: { autocomplete: "off" },
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.doSearch($event)
            }
          }
        },
        [
          _c("div", {
            staticClass:
              "close-toolbar-search toolbar_search_icon icon_cross icon_color-black mr-2 mr-lg-0 d-block d-md-none"
          }),
          _vm._v(" "),
          _c("div", { staticClass: "form-search flex-grow-1" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.searchVal,
                  expression: "searchVal"
                }
              ],
              staticClass: "w-100 h-100 text_montserrat-light text-size_15",
              attrs: { id: "search-lib", type: "text", placeholder: "BUSCAR" },
              domProps: { value: _vm.searchVal },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.searchVal = $event.target.value
                }
              }
            })
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }