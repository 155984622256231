import { render, staticRenderFns } from "./KIcon.vue?vue&type=template&id=3b307460&"
import script from "./KIcon.vue?vue&type=script&lang=js&"
export * from "./KIcon.vue?vue&type=script&lang=js&"
import style0 from "./KIcon.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\Code\\ZipVisual\\benchmark\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3b307460')) {
      api.createRecord('3b307460', component.options)
    } else {
      api.reload('3b307460', component.options)
    }
    module.hot.accept("./KIcon.vue?vue&type=template&id=3b307460&", function () {
      api.rerender('3b307460', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "assets/app/js/components/icons/KIcon.vue"
export default component.exports