var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      ref: "form",
      attrs: { model: _vm.form, rules: _vm.formRules },
      nativeOn: {
        submit: function($event) {
          $event.preventDefault()
          _vm.onSubmit()
        }
      }
    },
    [
      _c("div", { staticClass: "overflow-hidden" }, [
        _c("div", { staticClass: "position-relative" }, [
          _vm.extended
            ? _c(
                "div",
                {
                  staticClass:
                    "row background-color_tertiary demo-form_header position-relative"
                },
                [
                  _c(
                    "Swiper",
                    {
                      ref: "carouselClients",
                      staticClass: "contact-carousel",
                      attrs: { options: _vm.swiperOptions }
                    },
                    _vm._l(_vm.settlements, function(settlement, index) {
                      return _c(
                        "SwiperSlide",
                        {
                          key: index,
                          staticClass:
                            "col-12 col-lg-6 text-color_white text-center "
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "row h-100 px-3 px-lg-3",
                              staticStyle: { "place-content": "center" }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "col-12 text_montserrat-bold text-size_20"
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "row justify-content-center"
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "ml-1 k-icon-fix-pos" },
                                        [
                                          _c("k-icon", {
                                            attrs: { icon: "marker" }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("span", { staticClass: "ml-2" }, [
                                        _vm._v(_vm._s(settlement.title))
                                      ])
                                    ]
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "col-12 text_montserrat-regular text-size_15 mt-2"
                                },
                                [_vm._v(_vm._s(settlement.description))]
                              )
                            ]
                          )
                        ]
                      )
                    }),
                    1
                  ),
                  _vm._v(" "),
                  _c("div", {
                    ref: "settlementsSwiperPagination",
                    staticClass: "swiper-pagination d-md-none",
                    attrs: { id: "settlementsSwiperPagination" }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "row align-items-start justify-content-center mt-3 mt-lg-5 mb-2 mb-lg-2 position-relative",
              staticStyle: { "z-index": "10" }
            },
            [
              _c(
                "div",
                {
                  staticClass: "demo-form_bob",
                  class: { "demo-form_bob--flat": _vm.isFlat }
                },
                [
                  _c("img", {
                    staticClass: "img-fluid",
                    attrs: { src: _vm.bobImage, alt: "Bob" }
                  })
                ]
              ),
              _vm._v(" "),
              !_vm.hideClose
                ? _c(
                    "div",
                    {
                      staticClass:
                        "text-color_secondary d-flex demo-form_close",
                      class: { "close-contact-modal": _vm.isModal }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "background-color_main rounded-icon rounded-icon--medium k-icon-fix-pos text-color_white cursor-pointer"
                        },
                        [_c("k-icon", { attrs: { icon: "cross" } })],
                        1
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("div", {
                staticClass:
                  "main-gradient flex-grow-1 header-badge demo-form_left-bar d-none d-md-flex"
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "mx-3 demo-form_title text-md-left text-center mt-n1"
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "text_montserrat-bold text-size_17 text-color_main"
                    },
                    [_vm._v("SOLICITA UNA DEMO O UNA ASESORÍA")]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "text_montserrat-medium text-size_15 text-color_secondary"
                    },
                    [
                      _vm._v(
                        "Completa el siguiente formulario y un ejecutivo se pondrá en contacto"
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      staticClass:
                        "text_montserrat-medium text-color_main mt-2",
                      attrs: { prop: "solutionsOrInfrastructure" }
                    },
                    [
                      _c("div", { staticClass: "ml-1" }, [
                        _vm._v("INFRAESTRUCTURA")
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-select",
                        {
                          staticClass: "ml-0 demo-form_select",
                          staticStyle: { "margin-left": "20px" },
                          attrs: {
                            multiple: "",
                            "collapse-tags": "",
                            placeholder: "INFRAESTRUCTURA"
                          },
                          model: {
                            value: _vm.form.infrastructure,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "infrastructure", $$v)
                            },
                            expression: "form.infrastructure"
                          }
                        },
                        _vm._l(_vm.infrastructureOptions, function(item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value }
                          })
                        }),
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "ml-1" }, [
                        _vm._v("SOLUCIONES")
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-select",
                        {
                          staticClass:
                            "ml-0 demo-form_select text_montserrat-medium",
                          staticStyle: { "margin-left": "20px" },
                          attrs: {
                            multiple: "",
                            "collapse-tags": "",
                            placeholder: "SOLUCIONES"
                          },
                          model: {
                            value: _vm.form.solutions,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "solutions", $$v)
                            },
                            expression: "form.solutions"
                          }
                        },
                        _vm._l(_vm.solutionsOptions, function(item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("div", {
                staticClass:
                  "main-gradient_backwards flex-grow-1 header-badge d-none d-md-flex"
              })
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "row justify-content-center px-1" }, [
            _c("div", { staticClass: "col-12 col-lg-10 demo-form_body" }, [
              _c(
                "div",
                { staticClass: "row" },
                [
                  _c(
                    "el-form-item",
                    {
                      staticClass: "col-12 col-lg-12",
                      attrs: { prop: "name" }
                    },
                    [
                      _c("el-input", {
                        staticClass: "form-field",
                        attrs: { placeholder: "*NOMBRE COMPLETO" },
                        model: {
                          value: _vm.form.name,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "name", $$v)
                          },
                          expression: "form.name"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      staticClass: "col-12 col-lg-12",
                      attrs: { prop: "company" }
                    },
                    [
                      _c("el-input", {
                        staticClass: "form-field",
                        attrs: { placeholder: "*EMPRESA" },
                        model: {
                          value: _vm.form.company,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "company", $$v)
                          },
                          expression: "form.company"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      staticClass: "col-12 col-lg-12",
                      attrs: { prop: "email" }
                    },
                    [
                      _c("el-input", {
                        staticClass: "form-field",
                        attrs: { placeholder: "*EMAIL" },
                        model: {
                          value: _vm.form.email,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "email", $$v)
                          },
                          expression: "form.email"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      staticClass: "col-12 col-lg-12",
                      attrs: { prop: "phone" }
                    },
                    [
                      _c("el-input", {
                        staticClass: "form-field",
                        attrs: { placeholder: "*TELÉFONO" },
                        model: {
                          value: _vm.form.phone,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "phone", $$v)
                          },
                          expression: "form.phone"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "row text-center py-2" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c(
                    "span",
                    {
                      staticClass:
                        "text_montserrat-medium text-size_17 text-color_gray"
                    },
                    [
                      _vm._v(
                        "Selecciona una fecha y un horario para recibir nuestra llamada"
                      )
                    ]
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-12" }, [
                  _c(
                    "div",
                    {
                      staticClass: "d-flex justify-content-center mt-2 mt-lg-2"
                    },
                    [
                      _c("el-date-picker", {
                        staticClass: "demo-form_date",
                        attrs: { type: "datetime", format: "dd/MM/yyyy HH:mm" },
                        model: {
                          value: _vm.form.date,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "date", $$v)
                          },
                          expression: "form.date"
                        }
                      })
                    ],
                    1
                  )
                ])
              ])
            ])
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "row mx-0",
              class: { "demo-form_bottom": !_vm.hideBottom },
              staticStyle: { "padding-top": "4rem" }
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "primary-button cursor-pointer background-color_main text-size_15 text_montserrat-semibold mx-auto align-self-center px-4",
                  on: { click: _vm.onSubmit }
                },
                [
                  _c("div", { staticClass: "text-color_white " }, [
                    _c("span", {}, [_vm._v("Enviar")])
                  ])
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "col-12 text-center" }, [
                _c(
                  "span",
                  {
                    staticClass:
                      "text-size_12 text_montserrat-medium text-color_gray"
                  },
                  [_vm._v("*Todos los campos son obligatorios")]
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-12 text-center" }, [
                _c(
                  "a",
                  {
                    staticClass:
                      "text-size_9 text_montserrat-medium text-color_gray py-2",
                    attrs: { href: "/aviso-privacidad.pdf", target: "_blank" }
                  },
                  [_vm._v("Aviso de privacidad")]
                )
              ])
            ]
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }