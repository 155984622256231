import Vue from 'vue'
import ElementUI from 'element-ui';
import locale from 'element-ui/lib/locale/lang/es'
// import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'element-ui/lib/theme-chalk/index.css' // <- Default ElementUI styles package
// import "../../styles/element/theme/index.css" <- For custom ElementUI styles package

import KIcon from '../components/icons/KIcon'
import DemoForm from '../components/DemoForm'
import LibraryForm from '../components/LibraryForm'
import SearchField from '../components/Search'

Vue.use(ElementUI, {locale});

new Vue ({
    el: '#app',
    components: {
        KIcon,
        DemoForm,
        LibraryForm,
        SearchField,
    },
});