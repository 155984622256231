export const settlements = [
    {
        title: "GUADALAJARA",
        description: "Blvrd Puerta de Hierro 5200-Int. 19, Puerta de Hierro, 45110 Zapopan, Jal. +52.33.2400.9875"
    },
    {
        title: "PANAMÁ",
        description: "Torre Banesco, Oficina 1211 Ave. Aqulino de la Guardia Marbella, Panamá +507.395.6211"
    },
]

export const infrastructureOptions = [
    {
        value: "NUBE PRIVADA",
        label: "NUBE PRIVADA"
    },
    {
        value: "SD WAN",
        label: "SD WAN"
    },
    {
        value: "REDES Y COMUNICACIÓN",
        label: "REDES Y COMUNICACIÓN"
    },
    {
        value: "SEGURIDAD",
        label: "SEGURIDAD"
    },
    {
        value: "CONTINUIDAD DE NEGOCIO",
        label: "CONTINUIDAD DE NEGOCIO"
    },
    {
        value: "MONITOREO Y CONTROL",
        label: "MONITOREO Y CONTROL"
    },
];

export const solutionsOptions = [
    {
        value: "BENCHMAP",
        label: "BENCHMAP",
    },
    {
        value: "CIBER SEGURIDAD",
        label: "CIBER SEGURIDAD",
    },
    {
        value: "INTELIGENCIA DE NEGOCIOS",
        label: "INTELIGENCIA DE NEGOCIOS",
    },
    {
        value: "BIG DATA",
        label: "BIG DATA",
    },
    {
        value: "INFORMATICA LEGAL",
        label: "INFORMATICA LEGAL",
    },
];