import anime from 'animejs'
import $ from 'jquery'

$(".home_header, .home_badge, .home_benchmark-image, .home_benchmark-text, .home_mouse").css({opacity: 0})

let tl = anime.timeline({
    easing: 'easeOutExpo',
    begin: function () {
        $("body").css({overflow: 'hidden'})
    },
    complete: function () {
        if (window.isMobile) $("body").css({overflow: 'unset'})
    }
});

tl.add({
    targets: ".home_benchmark-image",
    opacity: 1,
    duration: 1000
}, '+=2000')
tl.add({
    targets: ".home_benchmark-text",
    opacity: 1,
    duration: 1000
}, '+=500')
tl.add({
    targets: ".home_header, .home_badge, .home_mouse",
    opacity: 1,
    duration: 500
})
tl.pause();

$(document).ready(() => {
    if (location.pathname == "/") { // Solo at home
        tl.play()
    }
})