<template>
    <div class="library-form row mx-0" v-if="visibleForm">
        <el-form class="demo-form demo-form--library" ref="form" :model="form" :rules="formRules" @submit.native.prevent="onSubmit ()" v-loading="loading">
            <div class="overflow-hidden">
                <div class="position-relative">
                    <div class="row align-items-center mt-3 mt-lg-3 mb-2 mb-lg-3 position-relative">

                        <div @click="() => {visibleForm = false}" class="cursor-pointer text-color_secondary d-flex library-form_close">
                            <div class="background-color_main rounded-icon rounded-icon--medium k-icon-fix-pos text-color_white cursor-pointer">
                                <k-icon icon="cross"></k-icon>
                            </div>
                        </div>

                        <div class="main-gradient flex-grow-1 header-badge"></div>
                        <div class="mx-1 demo-form_title text-left mt-n1">
                            <img class="library-form_icon" :src="benchmarkImage" alt="Bechmark icon">
                        </div> 
                        <div class="main-gradient_backwards flex-grow-1 header-badge"></div>
                    </div>

                    <div class="mt-lg-2 mb-lg-4 text-center">
                        <h3 class="text_montserrat-bold text-color_main text-size_30">LIBRERIA</h3>
                        <h5 class="text_montserrat-medium text-color_black text-size_15 mt-n1">Completa el siguiente formulario para acceder a la sección</h5>
                    </div>

                    <div class="row justify-content-center">
                        <div class="col-12 col-lg-10">
                            <div class="row">
                                <el-form-item class="col-12 col-lg-12" prop="name">
                                    <el-input v-model="form.name" class="form-field" placeholder="*NOMBRE"></el-input>
                                </el-form-item>
                                <el-form-item class="col-12 col-lg-12" prop="lastName">
                                    <el-input v-model="form.lastName" class="form-field" placeholder="*APELLIDOS"></el-input>
                                </el-form-item>
                                <el-form-item class="col-12 col-lg-12" prop="company">
                                    <el-input v-model="form.company" class="form-field" placeholder="*EMPRESA"></el-input>
                                </el-form-item>
                                <el-form-item class="col-12 col-lg-12" prop="email">
                                    <el-input v-model="form.email" class="form-field" placeholder="*EMAIL"></el-input>
                                </el-form-item>
                                <el-form-item class="col-12 col-lg-12" prop="phone">
                                    <el-input v-model="form.phone" class="form-field" placeholder="*TELÉFONO"></el-input>
                                </el-form-item>
                            </div>
                        </div>
                    </div>

                    <div class="row mx-0 demo-form_bottom" style="padding-top: 4rem" @click="onSubmit">
                        <div class="primary-button cursor-pointer background-color_main text-size_15 text_montserrat-semibold mx-auto align-self-center px-4">
                            <div class="text-color_white "><span class="">Enviar</span></div>
                        </div>

                        <div class="col-12 text-center">
                            <span class="text-size_12 text_montserrat-medium text-color_gray">*Todos los campos son obligatorios</span>
                        </div>
                        <div class="col-12 text-center">
                            <a href="/aviso-privacidad.pdf" target="_blank" class="text-size_9 text_montserrat-medium text-color_gray py-2">Aviso de privacidad</a>
                        </div>
                    </div>

                    <div class="library-form_bob demo-form_bob">
                        <img class="img-fluid" :src="bobImage" alt="Bob">
                    </div>
                </div>
            </div>
        </el-form>
    </div>
</template>

<script>
import KIcon from './icons/KIcon'
import {rules} from '../static/rules'
import axios from 'axios'
import {libraryIndex as isRegistered, registeredEventMaker, download} from '../static/libraryMethods'

export default {
    components: {
        KIcon,
    },
    props: {
        urlAction: {
            type: String,
            default: '/send-contact-mail'
        },
        token: {
            type: String,
            required: true
        },
        crmAction: {
            type: String,
            default: '/crm'
        },
        bobImage: {
            type: String,
        },
        benchmarkImage: {
            type: String,
        },
    },
    data () {
        return {
            form: {
                date: new Date()
            },
            loading: false,
            formRules: rules.libraryForm,
            visibleForm: false,
            registeredEvent: registeredEventMaker (),
            onFinishId: null
        }
    },
    methods: {
        onSubmit () {            
            this.$refs['form'].validate(async (valid) => {
                if (valid) {
                    this.loading = true
                    await this.sendForm ()
                } else {
                    return false;
                }
            });
        },
        async sendForm () {
            let form_data = new FormData();
            for ( let key in this.form ) {
                form_data.append(key, this.form[key]);
            }

            axios.post ("/library/save", form_data)
            .then(res => {
                this.saveToCRM ()
                setTimeout(() => {
                    window.dispatchEvent (this.registeredEvent)
                    this.download()
                    this.visibleForm = false
                }, 1000);
            })
            .catch (error => {
                this.$notify({title: 'Error', message: error});
            })
            .then(() => {
                this.loading = false
            })
        },
        download () {
            if (this.onFinishId) download (this.onFinishId)
        },
        async saveToCRM () {
            let body = {
                star_value: "5",
                lead_score: "100",
                tags: ["libraryform"],
                properties: this.postProperties,
                token: this.token,
            }
            return await axios.post (this.crmAction, body)
            .then (res => {
                console.log("Saved to crm")
            }).catch(error => {
                console.error ("Error on Library form post to CRM", error)
            })
        }
    },
    created () {
        window.addEventListener ('registerEvent', (e) => {
            this.onFinishId = e.detail.id
            if (isRegistered ()) {
                this.download()
            } else {
                this.visibleForm = true
            }
        })
    },
    computed: {
        postProperties () {
            return [
                {
                    type: "SYSTEM",
                    name: "first_name",
                    value: this.form.name
                },
                {
                    type: "SYSTEM",
                    name: "last_name",
                    value: this.form.lastName
                },
                {
                    name: "company",
                    type: "CUSTOM",
                    value: this.form.company
                },
                {
                    type: "SYSTEM",
                    name: "email",
                    value: this.form.email
                },
                {
                    name: "phone",
                    subtype: "home",
                    value: this.form.phone
                },
            ]
        }
    }
}
</script>