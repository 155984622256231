import $ from 'jquery'
import {registerEventMaker, saveHasBeenRegistered} from '../static/libraryMethods'

$(document).ready (() => {
    $(".library-item").on('click', function () {
        let link = $(this).data ('link')
        let id = $(this).data ('id')
        let registerEvent = registerEventMaker ({action: link, id})
        window.dispatchEvent (registerEvent)
    })

    window.addEventListener ('registeredEvent', () => {
        saveHasBeenRegistered (true)
    })
})