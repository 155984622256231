import $ from "jquery"
import anime from "animejs"

export const makeSlideTransitions = function (swiperInstance) {
    return {
        slideTransitionStart: function () {
            // homeCarouselImage
            // homeCarouselTitle
            // homeCarouselText
        },
        slideTransitionEnd: function () {
        }
    }
}