export const rules = {
    demoForm: {
        name: [
            { required: true, message: 'Este campo es requerido', trigger: 'blur' },
        ],
        phone: [
            { required: true, message: 'Este campo es requerido', trigger: 'change' },
            { min: 10, max: 10, trigger: 'blur', message: 'El telefono debe tener 10 numeros' },
        ],
        company: [
            { required: true, message: 'Este campo es requerido', trigger: 'blur' },
        ],
        infrastructure: [
            { required: true, message: 'Este campo es requerido', trigger: 'blur' },
        ],
        solutions: [
            { required: true, message: 'Este campo es requerido', trigger: 'blur' },
        ],
        solutionsOrInfrastructure: [
            { trigger: 'blur', validator: (rule, value, callback) => {
                if (!value) callback (new Error ("Debe seleccionar al menos un elemento de Infraestructura o Soluciones"))
                else callback()
            } },
        ],
        email: [
            { required: true, message: 'Este campo es requerido', trigger: 'blur' },    
            { type: 'email', message: 'Introduce un email valido', trigger: 'blur' },
        ],
    },
    libraryForm: {
        name: [
            { required: true, message: 'Este campo es requerido', trigger: 'blur' },
        ],
        lastName: [
            { required: true, message: 'Este campo es requerido', trigger: 'blur' },
        ],
        company: [
            { required: true, message: 'Este campo es requerido', trigger: 'blur' },
        ],
        phone: [
            { required: true, message: 'Este campo es requerido', trigger: 'change' },
            { min: 10, max: 10, trigger: 'blur', message: 'El telefono debe tener 10 numeros' },
        ],
        email: [
            { required: true, message: 'Este campo es requerido', trigger: 'blur' },    
            { type: 'email', message: 'Introduce un email valido', trigger: 'blur' },
        ],
    },
}