import $ from 'jquery';
import AOS from 'aos' 

const Preloader = () => {
    const setPreloader = () => {
        $(window).on('load', function () {
            window.onbeforeunload = function(){
                window.scrollTo(0,0);
            }
            removePreloader();
        });
    };

    const removePreloader = () => {
        setTimeout(() => {
            $('body').removeClass('position-fixed');
            $(".k-preloader").fadeOut(400);

            AOS.init({
                once: true,
                anchorPlacement: 'bottom-center',
                duration: 1100
            });
        }, 1000)
    };

    const showPreloader = () => {
        $('body').addClass('position-fixed');
        $(".k-preloader").fadeIn(400);
    };

    return {
        showPreloader,
        removePreloader,
        setPreloader
    }
};

export default Preloader();